<template>
  <div class="container customerpreview">
    <div class="row header">
      <div class="col">
        <HomeButton
          v-if="group"
          :group-id="group.id"
        />
        <BackButton :back-url="backUrlString" />
      </div>
    </div>
    <div v-if="showModal" class="modal">
      <div class="modal-content">
        <!-- Removed the close button (cross) -->
        <CustomerMoveForm :target-id="id" :current-group="currentGroup" :friend-management-groups="friendManagementGroups" @save="moveCustomerToFmg" @cancel="cancelMove" />
      </div>
    </div>
    <div
      v-if="loaded && group"
      class="row content"
    >
      <div v-if="!group.netFriendship" class="col-lg-4 mb-3 mb-lg-0 customer-area">
        <CustomerCardStandard
          v-if="customer && group"
          v-model="customer"
          :group="group"
          @refresh="refresh"
          @moveCustomer="moveCustomer"
        />
      </div>

      <div v-if="group.netFriendship" class="col-lg-4 mb-3 mb-lg-0 customer-area">
        <CustomerCardNet
          v-if="customer && group"
          v-model="customer"
          :group="group"
          @refresh="refresh"
        />
      </div>

      <div class="col-lg-8 volunteer-area">
        <div v-if="inactiveText" class="row">
          <div class="col-12">
            <div class="inactive text-center">
              <div>{{ inactiveText }}</div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="row">
            <div class="col-12">
              <h3 class="title">
                {{ $t('customerSearch.friendshipsTitle') }}
              </h3>
            </div>
          </div>
          <div class="customeractions">
            <customermatchstatus
              :customer="customer"
              :group="group"
              :highlightedvolunteerid="volunteerid"
              @addNewMatch="onAddNewMatch"
              @volunteerMatchSelected="onVolunteerMatchSelected"
            />
          </div>
          <div class="row">
            <div class="col-12">
              <h3 class="title">
                {{ $t('customerSearch.poolsTitle') }}
              </h3>
            </div>
          </div>
          <div v-if="loaded" class="row">
            <div class="col-12">
              <CustomerPoolsNet
                v-if="group.netFriendship"
                :customer="customer"
                :group="group"
                :is-active="isActive"
                @poolsupdated="onPoolsUpdated"
              />
              <CustomerPoolsStandard
                v-else
                :customer="customer"
                :group="group"
                :is-active="isActive"
                @poolsupdated="onPoolsUpdated"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!loaded">
      <div class="spinner">
        <Loading />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import { LocaleMessage } from 'vue-i18n'
  import * as _ from 'lodash'
  import BackButton from '../BackButton.vue'
  import HomeButton from '../HomeButton.vue'
  import CustomerCardStandard from './CustomerCardStandard.vue'
  import CustomerCardNet from './CustomerCardNet.vue'
  import customermatchstatus from './CustomerMatchStatus.vue'
  import CustomerPoolsStandard from './CustomerPoolsStandard.vue'
  import CustomerPoolsNet from './CustomerPoolsNet.vue'
  import CustomerMoveForm from './MoveToFMGForm.vue'
  import Loading from '../SPRLoading.vue'
  import { StringKeyValuePair } from '@/types/custom'

  import { CustomerState, FriendManagementDto, FriendCustomerStandardDto, FriendCustomerNetDto, FriendshipNetDto, FriendshipStandardDto } from '../../types/index'

  // the data object
  export interface TemplateComponentData {
    showModal: boolean,
    loaded: boolean,
    customer: FriendCustomerStandardDto | FriendCustomerNetDto | undefined,
    group: FriendManagementDto | undefined,
    backUrl: string,
    friendManagementGroups: Array<StringKeyValuePair>
  }

  export default Vue.extend({
    name: 'CustomerPreview',
    components: { CustomerCardStandard, BackButton, HomeButton, customermatchstatus, CustomerPoolsStandard, CustomerPoolsNet, Loading, CustomerCardNet, CustomerMoveForm },
    props: {
      id: {
        type: String,
        default: ''
      }, // the customer id
      groupid: {
        type: String,
        default: ''
      },
      volunteerid: {
        type: String,
        default: '',
        required: false
      } // optional. Used to highlight a specific match
    },
    data (): TemplateComponentData {
      return {
        friendManagementGroups: [] as Array<StringKeyValuePair>,
        showModal: false,
        customer: undefined,
        group: undefined,
        backUrl: '',
        loaded: false
      }
    },
    computed: {
      currentGroup (): StringKeyValuePair | null {
        var group = _.find(this.friendManagementGroups, { key: this.groupid })
        return group
      },
      backUrlString (): string {
        return '/friend/search/' + this.groupid + '/customer'
      },
      isActive (): boolean {
        return _.includes([CustomerState.Active, CustomerState.PendingFriend, CustomerState.ActiveFriendship], this.customer?.state)
      },
      isInactive (): boolean {
        return this.customer?.state === CustomerState.NonActive
      },
      inactiveText (): string | LocaleMessage {
        switch (this.customer?.state) {
        case CustomerState.PendingApproval:
          return this.$t('customerSearch.noMatchingNotInterviewed')
        case CustomerState.NonActive:
          return this.$t('customerSearch.noMatchingRemoved')
        case CustomerState.OnHold:
          return this.$t('customerSearch.noMatchingOnHold')
        }
        return ''
      }
    },
    created () {
      if (this.isMoveAdmin()) {
        const url = this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.friendstandardgroups[0]
        const self = this
        this.$http.get(url).then((resp: any) => {
          if (resp.body) {
            var friendManagementGroups = [] as Array<StringKeyValuePair>
            _.each(resp.body, (fmg) => {
              friendManagementGroups.push({ key: `${fmg.id}`, value: `${fmg.name}` })
            })

            this.friendManagementGroups = _.sortBy(friendManagementGroups, 'value')
          }
        })
      }
    },
    mounted (): void {
      this.backUrl = `/friend/search/${this.groupid}/customer`
      this.load()
    },
    methods: {
      isMoveAdmin (): boolean {
        // @ts-ignore
        const isAdmin = this.hasRole('Admin')
        // @ts-ignore
        const isFriendshipAdmin = this.hasRole('FriendshipAdmin')
        // @ts-ignore
        const isDistrictFriendshipAdmin = this.hasRole('DistrictFriendshipAdmin')
        return isAdmin || isFriendshipAdmin || isDistrictFriendshipAdmin
      },
      goBackUrl (): string {
        return `/friend/search/${this.groupid}/customer`
      },
      load (): void {
        var getGroupUrl = `${this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.friendOrganizationById[0]}`.replace('{0}', this.groupid)

        this.loaded = false
        this.$http.get(getGroupUrl).then(resp => {
          // @ts-ignore
          this.group = resp.body
          var getCustomerUrl = this.group?.netFriendship
            ? `${this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.customergetnetbyid[0]}`.replace('{customerid}', this.id).replace('{groupid}', this.groupid)
            : `${this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.customergetstandardbyid[0]}`.replace('{customerid}', this.id).replace('{groupid}', this.groupid)
          return this.$http.get(getCustomerUrl)
        })
          .then(resp => {
            // @ts-ignore
            this.customer = resp.body
            this.loaded = true
          }, f => {
            this.loaded = true
            this.displayError(f)
          })
      },
      moveCustomer (): void {
        this.showModal = true
      },
      moveCustomerToFmg (selectedGroup): void {
        if (selectedGroup === null) {
          return
        }
        const url = this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.customermovetodifferentfmg[0]

        const moveToFMGCommand = {
          TargetId: this.id,
          GroupId: selectedGroup.key
        }

        this.$http.put(url, moveToFMGCommand).then((resp: any) => {
          if (resp.status === 200) {
            // Handle success response
            // @ts-ignore
            this.showAlertInfo(this.$t('customerSearch.movingCustomer'))
          } else if (resp.status === 403) {
            // Handle forbidden response
            this.$router.push('/profile')
          } else if (resp.status === 400) {
            // Handle bad request response
            // @ts-ignore
            this.showAlertError(this.$t('customerSearch.failedToMove'))
          }

          this.showModal = false
        }, (error: any) => {
          // Handle any other errors
          console.log('Error:', error)
          // @ts-ignore
          this.showAlertError(this.$t('customerSearch.failedToMove'))

          this.showModal = false
        })
      },
      cancelMove (): void {
        this.showModal = false
      },
      closeMoveCustomer (): void {
        this.showModal = false
      },
      onAddNewMatch (): void {
        this.$router.push(`/friend/customermatch/${this.groupid}/${this.id}`)
      },
      onVolunteerMatchSelected (friendship: FriendshipNetDto | FriendshipStandardDto): void {
        this.$router.push(`/friend/customermatch/${this.groupid}/${this.id}/${friendship.volunteer.volunteerId}`)
      },
      onPoolsUpdated (): void {
        this.load()
      },
      refresh (): void {
        // reload
        this.$router.go(0)
      },
      displayError (err: Response | any): void {
        if (Array.isArray(err.body)) {
          err.body.forEach(x => {
            // @ts-ignore
            this.showAlertError(x.errorMessage)
          })
        } else if (err.body && err.body.errorMessage) {
          // @ts-ignore
          this.showAlertError(err.errorMessage)
        } else if (err.bodyText) {
          var backUrl = this.goBackUrl()
          this.$router.push(backUrl)
        } else {
          // @ts-ignore
          this.showAlertError(this.$t('common.fetchFailed'))
        }
      }
    }
  })
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
  .customerpreview {
    .spinner {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100vh;
    }
    .header {
      margin-top: 10px;
      margin-bottom: 15px;
      .nav-header {
        border-bottom: 0;
      }
    }

    .content {
      .customer-area {
        padding-top: 30px;
        background-color: $brand-white;
        border-left: solid 5px $customer-color-strong;
      }
    }
  }

.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  max-width: 600px;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}
</style>
