<template>
  <div class="search-section">
    <div class="search-criteria">
      <div class="search-group">
        <div class="search-item">
          <InfoTip
            :title="$t('eventSearch.searchWord')"
            tipId="search-word"
            :tipContent="$t('eventInfo.searchWord')"
            :linkUrl="''"
            iconAlt="Help Icon"
          />
          <span class="form-group">
            <div :class="{ 'spr-searchbox': true, 'spr-roundbox-1': true }">
              <input v-model="searchWord" class="form-control" tabindex="0" :placeholder="$t('eventSearch.searchWordPlaceHolder')">
            </div>
          </span>
        </div>
        <div class="search-item">
          <InfoTip
            :title="$t('eventPage.city')"
            tipId="location-info"
            :tipContent="$t('eventInfo.locationInfo')"
            :linkUrl="''"
            iconAlt="Help Icon"
          />
          <div class="form-group">
            <div :class="{ 'spr-searchbox': true, 'spr-roundbox-1': true }">
              <input v-model="locale" class="form-control" tabindex="0" :placeholder="$t('eventSearch.locationPlaceHolder')">
            </div>
          </div>
        </div>
      </div>
      <div class="search-item">
        <InfoTip
            :title="$t('eventPage.startDate')"
            tipId="start-date-info"
            :tipContent="$t('eventInfo.startDateInfo')"
            :linkUrl="''"
            iconAlt="Help Icon"
          />
        <datepicker
          :key="componentKey"
          v-model="searchStartDate"
          :initial="displayInitialDate"
          :has-error="errors.has('eventPage.startDate')"
          name="searchCriteria.startDate"
          :required="false"
          :placeholder="$t('eventPage.chooseDate')"
        />
      </div>
      <div class="search-item">
        <InfoTip
            :title="$t('eventPage.endDate')"
            tipId="end-date-info"
            :tipContent="$t('eventInfo.endDateInfo')"
            :linkUrl="''"
            iconAlt="Help Icon"
          />
        <datepicker
          :key="componentKey"
          v-model="searchEndDate"
          :initial="displayEndDate"
          :has-error="errors.has('eventPage.endDate')"
          name="searchCriteria.endDate"
          :required="false"
          :is-nullable="true"
          :placeholder="$t('eventPage.chooseDate')"
          :limit="[{type: 'fromto', from: processStartDate, to: null}]"
        />
      </div>
      <div class="search-item advanced-search-select-button">
        <div class="advanced-search-select" @click="toggleAdvancedSearch">
          <span class="advanced-search-select-text">{{ $t('searchCriteria.advancedSearchTitleEvent') }}</span>
          <font-awesome-icon v-if="advancedSearchVisible" icon="chevron-down" class="advanced-search-select-icon" aria-hidden="true" />
          <font-awesome-icon v-else icon="chevron-up" class="advanced-search-select-icon" aria-hidden="true" />
        </div>
      </div>
    </div>
    <div v-if="advancedSearchVisible" class="extended-advanced-search">
      <div class="search-criteria-title">
        {{ title }}
      </div>
      <div class="search-criteria">
        <div class="search-item">
          <InfoTip
            :title="$t('eventPage.isOnlineFilter')"
            tipId="is-onlinefilter-info"
            :tipContent="$t('eventInfo.onlineActivityInfo')"
            :linkUrl="''"
            iconAlt="Help Icon"
          />
          <singleselect
            :key="componentKey"
            v-model="isOnline"
            :items="isOnlineValues"
            @change="onIsOnlineChanged"
          />
        </div>
        <div class="search-item">
          <InfoTip
            :title="$t('eventPage.eventCategory')"
            tipId="event-category-info"
            :tipContent="$t('eventInfo.activityInfo')"
            :linkUrl="''"
            iconAlt="Help Icon"
          />
          <singleselect
            :key="componentKey"
            v-model="eventCategory"
            :items="categories"
            @change="onCategoriesChanged"
          />
        </div>
        <div class="search-item">
          <InfoTip
            :title="$t('eventPage.eventType')"
            tipId="event-type-info"
            :tipContent="$t('eventInfo.typeInfo')"
            :linkUrl="''"
            iconAlt="Help Icon"
          />
          <singleselect
            :key="componentKey"
            v-model="eventType"
            :items="eventTypes"
            @change="onEventTypesChanged"
          />
        </div>
        <div class="search-item">
          <InfoTip
            :title="$t('eventPage.searchLanguages')"
            tipId="search-languages-info"
            :tipContent="$t('eventInfo.languageInfo')"
            :linkUrl="''"
            iconAlt="Help Icon"
          />
          <multiselectinput
            :key="componentKey"
            v-model="languages"
            :items="eventLanguages"
            @change="onLanguageChanged"
          />
        </div>
        <div class="search-item eventorganizer">
          <InfoTip
            :title="$t('eventPage.eventDistrictSection')"
            tipId="activity-organizer-info"
            :tipContent="$t('eventInfo.activityOrganizerInfo')"
            :linkUrl="''"
            iconAlt="Help Icon"
          />
          <eventorganizer
            name="eventPage.eventDistrictSection"
            :value="eventOrganizer"
            :required="false"
            :is-valid="true"
            @input="onEventOrganizerChanged"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import eventorganizer from '../SPREventOrganizersDropdown.vue'
  import singleselect from '../common/Dropdown/SingleSelectDropdown.vue'
  import datepicker from '../SPRDatepicker.vue'
  import multiselectinput from '../common/Dropdown/MultiSelectDropdown.vue'
  import { isEventOrganizerIdentifier } from '@/types/typevalidators'
  import { VueMultiSelectItem, EventOrganizerIdentifier } from '@/types/custom'
  import cache from '../../mixins/cache.js'
  import enumhelper from '../../mixins/enums.js'
  import inpututils from '../../mixins/inpututils'
  import moment from 'moment'
  import InfoTip from '@/components/common/Information/SprInfoTip.vue'

  // the data object
  export interface TemplateComponentData {
  advancedSearchVisible: boolean,
  disabled: boolean,
  isSearching: boolean,
  searchTimeout: ReturnType<typeof setTimeout> | undefined,
  searchWaitIntervalMs: number,
  eventCategoryData: VueMultiSelectItem | undefined,
  categories: VueMultiSelectItem[],
  languages: VueMultiSelectItem[],
  eventLanguages: VueMultiSelectItem[],
  eventTypeData: VueMultiSelectItem | undefined,
  eventTypes: VueMultiSelectItem[],
  eventOrganizer: EventOrganizerIdentifier,
  searchStartDate: string | null,
  searchEndDate: string | null,
  searchWord: string,
  locale: string,
  isOnlineData: VueMultiSelectItem | undefined,
  isOnlineValues: VueMultiSelectItem[],
  componentKey: number
}

  export default Vue.extend({
    name: 'EventSearchCriteria',
    components: { multiselectinput, singleselect, eventorganizer, datepicker, InfoTip },
    mixins: [cache, enumhelper, inpututils],
    props: {
      title: {
        type: String,
        default: ''
      },
      isPublic: {
        type: Boolean,
        default: false
      },
      searchValues: {
        type: Object,
        default: () => ({})
      }
    },
    data (): TemplateComponentData {
      return {
        advancedSearchVisible: false,
        disabled: false,
        isSearching: false as boolean,
        searchTimeout: undefined as unknown as ReturnType<typeof setTimeout>,
        searchWaitIntervalMs: 600 as number,
        eventCategoryData: { id: -1, text: '' } as VueMultiSelectItem | undefined, // initial dummy value to be replaced
        categories: [] as VueMultiSelectItem[],
        languages: [] as VueMultiSelectItem[],
        eventLanguages: [] as VueMultiSelectItem[],
        eventTypeData: { id: -1, text: '' } as VueMultiSelectItem | undefined, // initial dummy value to be replaced
        eventTypes: [] as VueMultiSelectItem[],
        eventOrganizer: {} as EventOrganizerIdentifier,
        searchStartDate: null as string | null,
        searchEndDate: null as string | null,
        searchWord: '' as string,
        locale: '' as string,
        isOnlineData: { id: -2, text: '' } as VueMultiSelectItem | undefined, // initial dummy value to be replaced
        isOnlineValues: [{ id: 0, text: 'All' }] as VueMultiSelectItem[],
        componentKey: 0 // forces reactive update of datepicker when language changes

      }
    },
    computed: {
      displayEndDate (): string | null {
        return null
      },
      displayInitialDate (): string | null {
        return moment().format('DD.MM.YYYY')
      },
      processStartDate (): string | null {
        return this.searchStartDate ? moment(this.searchStartDate, 'DD.MM.YYYY').format('YYYY-MM-DD') : null
      },
      isOnline: {
        get (): VueMultiSelectItem | undefined {
          return this.isOnlineData
        },
        set (newValue: VueMultiSelectItem | undefined) {
          this.isOnlineData = newValue
        }
      },
      eventCategory: {
        get (): VueMultiSelectItem | undefined {
          return this.eventCategoryData
        },
        set (newValue: VueMultiSelectItem | undefined) {
          this.eventCategoryData = newValue
        }
      },
      eventType: {
        get (): VueMultiSelectItem | undefined {
          return this.eventTypeData
        },
        set (newValue: VueMultiSelectItem | undefined) {
          this.eventTypeData = newValue
        }
      }
    },
    watch: {
      searchStartDate: {
        handler (newVal) {
          this.modifySearchCriteria('startDate', newVal)
        }
      },
      searchEndDate: {
        handler (newVal) {
          this.modifySearchCriteria('endDate', newVal)
        }
      },
      searchValues: {
        handler (newVal) {
          if (!newVal) return

          if (newVal.searchWord) {
            this.searchWord = newVal.searchWord
          }

          if (newVal.locale) {
            this.locale = newVal.locale
          }

          if (newVal.eventCategory !== undefined) {
            this.eventCategoryData = this.categories.find((x: any) => x.id === newVal.eventCategory)
          } else { this.eventCategoryData = { id: 0, text: this.$t('boolean.notDefined') as string } }

          if (newVal.eventType !== undefined) {
            this.eventType = this.eventTypes.find((x: any) => x.id === newVal.eventType)
          } else { this.eventTypeData = { id: 0, text: this.$t('boolean.notDefined') as string } }

          if (newVal.isOnline !== undefined) {
            this.isOnlineData = this.isOnlineValues.find((x: any) => x.id === newVal.isOnline)
          } else {
            this.isOnlineData = { id: -1, text: this.$t('eventPage.isOnlineAll') as string }
          }

          if (newVal.languages !== undefined) {
            this.languages = this.eventLanguages.filter(l => newVal.languages.includes(l.id))
          }

          if (newVal.languages !== undefined) {
            this.languages = this.eventLanguages.filter(l => newVal.languages.includes(l.id))
          }

          if (isEventOrganizerIdentifier(newVal.eventOrganizer)) {
            this.eventOrganizer = newVal.eventOrganizer
          }
        },
        deep: true
      },
      searchWord (value) {
        this.delayedModify('searchWord', value)
      },
      locale (value) {
        this.delayedModify('locale', value)
      }
    },
    mounted () {
      this.searchStartDate = moment().format('DD.MM.YYYY')
      const lang = this.$store.state.customerLanguages
      this.eventLanguages = this.$store.state.groupLanguages.filter((l) => l.id !== 0).map((v) => {
        return { id: v.id, text: this.$t('actionLanguage.' + lang.find((l) => l.displayName.toUpperCase() === v.text.toUpperCase()).isoCode) }
      })

      this.isOnlineValues = [{ id: -1, text: this.$t('eventPage.isOnlineAll') as string }, { id: 0, text: this.$t('eventPage.isOnlineFalse') as string }, { id: 1, text: this.$t('eventPage.isOnlineTrue') as string }]

      this.categories = [{ id: 0, text: this.$t('boolean.notDefined') as string }].concat(this.$store.state.involvedEventCategories.map((v) => { return { id: v.id, text: this.$t('involvedEventCategories.' + v.text) } }))
      this.eventTypes = [{ id: 0, text: this.$t('boolean.notDefined') as string }].concat(this.$store.state.eventTypes.map((v) => { return { id: v.id, text: this.$t('eventTypes.' + v.text) } }))
      this.$store.subscribe((mutation, state) => {
        if (mutation.type === 'setLanguage') {
          this.componentKey += 1
        }
      })
    },
    methods: {
      toggleAdvancedSearch (): void {
        this.advancedSearchVisible = !this.advancedSearchVisible
      },
      onLanguageChanged (value) {
        if (!value) return
        // Convert each item.id to enum value (int)
        const enumValues = value.map(v => v.id)
        this.modifySearchCriteria('languages', enumValues)
      },
      onCategoriesChanged (value) {
        this.modifySearchCriteria('eventCategory', value.id === 0 ? undefined : value.id)
      },
      onIsOnlineChanged (value) {
        this.modifySearchCriteria('isOnline', value.id === -1 ? undefined : value.id)
      },
      onEventOrganizerChanged (value: EventOrganizerIdentifier): void {
        const searchCriteria = Object.assign({}, this.searchValues, { eventOrganizer: Object.freeze(value) })
        this.$emit('change', searchCriteria)
      },
      onEventTypesChanged (value): void {
        this.modifySearchCriteria('eventType', value.id === 0 ? undefined : value.id)
      },
      delayedModify (criterion, value) {
        clearTimeout(this.searchTimeout as ReturnType<typeof setTimeout>)
        this.searchTimeout = setTimeout(() => {
          this.modifySearchCriteria(criterion, value)
        }, this.searchWaitIntervalMs)
      },
      modifySearchCriteria (criterion, value) {
        const searchCriteria = Object.assign({}, this.searchValues, { [criterion]: value })
        this.$emit('change', searchCriteria)
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import '~@/assets/scss/_variables.scss';
  .cap-info-label {
    display: inline-flex;
    align-items: center; /* Optional: aligns items vertically if needed */
    gap: 8px;
  }

  .spr-searchbox {
    background-color:$brand-white;
    position: relative;
    border: solid 1px $brand-grey3;

    input
    {
      margin-right: 30px;
      border-radius: 2rem;
      &:focus-visible {
        color: #495057;
        border: solid 1px $brand-grey3;
        outline: 0;
        box-shadow: 0 0 0 0.2rem $brand-grey3;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      }

    }
    svg {
      position: absolute;
      right: 15px;
      top: 10px;
    }
   }
   .spr-datepicker {
    margin-bottom: 15px;

    .datepickbox {
      position: relative;
      input {
        position: relative;
        z-index: 1;
        box-shadow: none !important;
        border: solid 2px $brand-grey3 !important;
        background: none !important;
        width: 100%;
      }
    }
  }
  .form-group {
    .spr-roundbox-1 {
      border: none;
      select.form-control {
        -webkit-appearance: none;
        -moz-appearance: none;
        -o-appearance: none;
        appearance: none;
        background-image: url('../../assets/img/caret.png');
        background-position: 95% center;
        background-repeat: no-repeat;
        outline: none;
        border: solid 1px $brand-grey3;
        &:focus {
          box-shadow: 0 0 0 0.2rem $brand-grey3;
        }
      }
    }
  }

 .search-section {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
  }
  .search {
    display: flex;
    flex: 1 0 auto;
    flex-direction: row;
  }

  .search-item {
    flex: 1 0 auto;
    max-width: 100%;
    min-width: 252px;
    margin-right: 20px;
  }

  .searchbox {
        flex: 1 0 auto;
  }

  .advanced-search {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    justify-content: space-between;
    max-width: 100%;
    flex-wrap: wrap;
  }

  .extended-advanced-search{
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    justify-content: space-between;
    max-width: 100%;
    flex-wrap: wrap;
  }

  .search-group {
    display: flex;
    flex: 1 0 auto;
    flex-direction: row;
    justify-content: space-between;
    max-width: 100%;
    flex-wrap: wrap;
  }

  .advanced-search-select-button {
    flex: 1 0 auto;
    max-width: 100%;
    min-width: 252px;
    align-content: space-evenly;
    text-align: center;
    :hover {
      color: $brand-white;
      background-color: $brand-red;
    }
  }

  .advanced-search-select{
    flex: 0 0 auto;
    align-self: center;
    align-content: center;
    border: solid 2px $brand-grey3;
    border-radius: 2rem;
    cursor: pointer;
    color: $brand-red;
    background-color: $brand-white;
    height: 40px;
    margin-left: unset;
    margin-top: 27px;
    margin-bottom: 15px;
    max-width: 100%;
  }

  .advanced-search-select-text{
    user-select: none;
  }

  .advanced-search-select-icon{
    margin-left: 10px;
  }

</style>
