import { render, staticRenderFns } from "./SprInfo.vue?vue&type=template&id=6d35c453&scoped=true"
import script from "./SprInfo.vue?vue&type=script&lang=ts"
export * from "./SprInfo.vue?vue&type=script&lang=ts"
import style0 from "./SprInfo.vue?vue&type=style&index=0&id=6d35c453&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d35c453",
  null
  
)

export default component.exports