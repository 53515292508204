<template>
  <div class="comp-delete-profile-list">
    <div v-if="profiledeletionrequests" class="listing">
      <div v-for="(d, i) in profiledeletionrequests" :key="i" class="row listing-row">
        <div class="col-11">
          <router-link v-if="d.origin === 2" :to="'/admin/user/' + d.profileId">
            {{ d.firstName }} {{ d.lastName }} ({{ toFinnishDate(d.created) }}) {{ $t('profileDeleteRequest.origin' + getProfileDeleteRequestOriginText(d.origin)) + '. ' + $t('profile.membershipStatus') + ": " + getMembershipStatusText(d.membershipStatus) }}
          </router-link>
          <router-link v-else :to="'/admin/user/' + d.profileId">
            {{ d.firstName }} {{ d.lastName }} ({{ toFinnishDate(d.created) }}) {{ $t('profileDeleteRequest.origin' + getProfileDeleteRequestOriginText(d.origin)) }}
          </router-link>
        </div>
        <div class="col-1">
          <div>
            <font-awesome-icon icon="trash-alt" class="clickable-icon" @click="handleDelete(d.id)" />
          </div>
        </div>
      </div>
    </div>
    <div v-else class="loading text-center brand-red">
      <div class="my-2">
        <loading />
      </div>
      <div class="my-3">
        <p>{{ $t('userAdmin.loading') }}</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'

  import loading from '../SPRLoading.vue'
  import moment from 'moment'
  import { ProfileDeleteRequestDto } from '@/types'

  // the data object
  export interface TemplateComponentData {
    profiledeletionrequests: Array<ProfileDeleteRequestDto> | undefined
}
  export default Vue.extend({
    name: 'DeleteProfileList',
    components: { loading },
    data (): TemplateComponentData {
      return {
        profiledeletionrequests: undefined
      }
    },
    mounted (): void {
      //@ts-ignore
      if (!this.hasAdmin()) {
        // @ts-ignore (cannot find mixins)
        this.showAlertPermissionDenied().then(() => this.$router.push('/profile'))
      } else {
        this.fetchProfileDeletionRequests()
      }
    },
    methods: {
      fetchProfileDeletionRequests (): void {
        const url = this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.profiledeleterequests[0]
        this.$http.get(url).then(success => {
          this.profiledeletionrequests = success.data as Array<ProfileDeleteRequestDto>
        }, f => {
          // @ts-ignore (cannot find mixins)
          this.showAlertError(this.$t('common.fetchFailed'))
        })
      },
      toFinnishDate (date): string {
        if (date) {
          return moment.utc(date).local().format('D.M.YYYY H.mm')
        }
        return ''
      },
      handleDelete (id): void {
        if (!confirm(this.$t('profileDeleteRequest.confirmText').toString())) return

        const url = this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.deleteprofiledeleterequest[0]

        this.$http.delete(url + '/' + id).then(success => {
          this.fetchProfileDeletionRequests()
        }, f => {
          // @ts-ignore (cannot find mixins)
          this.showAlertError(this.$t('common.deleteFailed'))
        })
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import '~@/assets/scss/_variables.scss';
  .comp-delete-profile-list {
    background: $brand-white;
    padding: 15px;

    .listing {
      display: flex;
      display: -ms-flexbox;
      flex-direction: column;
      -ms-flex-direction: column;

      .listing-row {
        padding: 10px 15px;
        color: $brand-red;

        &:nth-child(even) {
          background: rgba(0,0,0,0.03);
        }

        a {
          color: $brand-red;
        }
      }
    }
    .clickable-icon {
      cursor: pointer;
    }
  }
</style>
