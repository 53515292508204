<template>
  <div class="info-header">
    <div class="cap-label">
      <div class="cap-info-label">{{ title }}

        <span :id="'caption-' + tipId">
          <font-awesome-icon :icon="['faR',  'circle-question']" class="brand-red" aria-hidden="true" @click="toggleTip"/>
          <FastInfoTip
            v-if="isTipVisible"
            :tipId="tipId"
            :content="tipContent"
            :linkUrl="linkUrl"
            :linkText="linkText"
            :infoPosition="infoPosition"
            @toggleTip="toggleTip($event)"
          />
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import FastInfoTip from './SprInfo.vue'

export default Vue.extend({
  name: 'IconCaption',
  components: { FastInfoTip },
  props: {
    title: {
      type: String,
      required: true
    },
    tipId: {
      type: String,
      required: true
    },
    tipContent: {
      type: String,
      required: true
    },
    linkUrl: {
      type: String,
      default: ''
    },
    linkText: {
      type: String,
      default: ''
    },
    iconAlt: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isTipVisible: false,
      infoPosition: {
        top: '0px',
        left: '0px'
      }
    }
  },
  methods: {
    toggleTip(event: PointerEvent) {
      if (event)
      {
        this.infoPosition.top = event.y + 'px'
      }

      this.isTipVisible = !this.isTipVisible
    }
  },
})
</script>

<style lang="scss" scoped>
  @import '~@/assets/scss/_variables.scss';

  .info-header {
    flex: 1 0 auto;
    max-width: 100%;
    min-width: 252px;
    margin-right: 20px;
  }
  
  .searchbox {
        flex: 1 0 auto;
  }

</style>
