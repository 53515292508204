<template>
  <div class="info-box-container"  :style="infoPositionStyles">
    <div class="info-box-close-row">
      <button class="info-box-close" role="button" @click="emitClickEvent()"></button>
    </div>
    <div
      :id="'info-box-' + tipId"
      class="info-box"
      ref="helpBox"
    >
      <div
        class="info-box-content"
        :id="'info-box-' + tipId + '-content'"
      >
        <span
          tabindex="0"
          v-html="content"
        ></span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'FastInfoTip',
  props: {
    tipId: {
      type: String,
      required: true
    },
    content: {
      type: String,
      required: true
    },
    linkUrl: {
      type: String,
      default: ''
    },
    linkText: {
      type: String,
      default: ''
    },
    infoPosition: {
      type: Object as () => { top: string; left: string },
      default: () => {
        return {
          top: '0px',
          left: '0px'
        }
      }
    } 
  },
  data() {
    return {
      
    }
  },
  computed: {
    infoPositionStyles(): { top: string; left: string } {
      const top = parseInt(this.infoPosition.top.replace('px', ''))

      return {
        top: (top - 118) + 'px',
        left: this.infoPosition.left
      } as { top: string; left: string }
    }
  }
  ,
  created() {
    this.showHelpInfo()
  },
  methods: {
    emitClickEvent() {
      this.$emit('toggleTip')
    },
    showHelpInfo() {
      document.addEventListener('click', this.handleOutsideClick)
      window.addEventListener('scroll', this.handleScroll)
      document.addEventListener('keydown', this.handleKeyDown)
    },
    hideHelpInfo() {
      document.removeEventListener('click', this.handleOutsideClick)
      window.removeEventListener('scroll', this.handleScroll)
      document.removeEventListener('keydown', this.handleKeyDown)
      this.$emit('toggleTip')
    },
    handleOutsideClick(event) {
      const helpBox = this.$refs.helpBox as HTMLElement
      const specificElement = event.target.closest('#caption-' + this.tipId)
      // Check if clicked inside help box or on the specific ID
      if (!helpBox.contains(event.target) && !specificElement) {
        this.hideHelpInfo()
      }

    },
    handleScroll() {
      // Close the help box on scroll
      this.hideHelpInfo()
    },
    handleKeyDown(event) {
      // Close the help box on escape key press
      if (event.key === 'Escape') {
        this.hideHelpInfo()
      }
    } 
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleOutsideClick)
    window.removeEventListener('scroll', this.handleScroll)
    document.removeEventListener('keydown', this.handleKeyDown)

  }

})
</script>

<style lang="scss" scoped>
  @import '~@/assets/scss/_variables.scss';

.info-box-container {
  position: Absolute; /* Absolutely positioned for overlapping */
  margin: 20px;
  margin-left: 14px;
  top: 50%; /* Center vertically */
  left: 0%; /* Center horizontally */
  transform: translate(-0%, -50%); /* Offset by 50% for perfect centering */
  max-width: 90vw; /* Box width should not exceed 90% of the viewport width */
  width: auto; /* Box width should adjust based on content */
  background-color: #fff0;
  z-index: 1000; /* Ensure it is above other content */
}


.info-box {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional shadow for visibility */
  overflow: hidden; /* Hide anything that overflows */
  box-sizing: border-box;
  padding: 11px 1px; /* Reduce left and right padding, keep top/bottom padding as is */
  margin: -11px 11px;
  background-color: $brand-white;
  border-color: #ccc;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14),
    0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.info-box-content {
  width: 100%;
  padding: 20px;
  height: auto; /* Automatically adjusts to content */
  display: inline-block; /* Allows the content to adjust based on text length */
}

.info-box span {
  display: block;
  word-wrap: break-word; /* Wrap long words within the box */
  overflow-wrap: break-word;
  white-space: normal; /* Allow wrapping of the text */
}

.info-box-default {
  border: 1px solid #f1d031;
  background-color: #ffffa3;
  color: #555;
  background-color: transparent !important;
  border: 0px !important;
  width: 8px;
  height: 8px;
  line-height: 8px;
  left: 50%;
  margin-left: -4px;
  top: -8px;
}

.info-box-close-row {
  display: flex;
  justify-content: right;
}

.info-box-close::before {
  display: inline-block;
  text-indent: 0;
  height: 10px;
  width: 10px;
  content: "✕";
  
  &:hover {
      background: $brand-red;
  }
}

.info-box-close {
  z-index: 1001;
  cursor: pointer;
  outline: 0;
  background: $brand-back;
  color: $brand-white;
  height: 22px;
  width: 22px;
  border-radius: 50%;
  border: none;

  &:hover {
      background: $brand-red;
  }

}

</style>
